<template>
  <div>
    <heade></heade>
    <div
      class="com"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="com_box">
        <div class="com-left">
          <div class="com-left-top title">资源展示</div>
          <div class="com_title_box">
            <div class="com_title nice-scroll">
              <div
                v-for="(items, indexs) in navs"
                :key="indexs"
                class="nav_list"
                :id="'trigger' + indexs"
                v-trigger
                @click="getResourceDetail(items.id, indexs)"
              >
                <p
                  :class="queryWhere.id == items.id ? 'active' : ''"
                  v-if="indexs < navs.length - 1"
                >
                  {{ items.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="resource-display">
          <van-tabs v-model="tabActive">
            <van-tab
              v-for="(items, indexs) in resourceDetail.tabs"
              :key="indexs"
              :title="items.name"
            >
              <div
                :class="indexs != 1 ? 'content addP' : 'content'"
                v-html="resourceDetail[items.field_name]"
              ></div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="com-btt">
          <div v-for="(item, index) in navs" :key="index">
            <router-link
              v-if="index < navs.length - 1"
              :to="{
                path: '/resourceDisplay',
                query: { id: item.id, edition_id: 1 },
              }"
              class="com-bbt-item"
              :class="
                item.id == pract.id ? 'com-bbt-item active2' : 'com-bbt-item'
              "
              >{{ item.name }}</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <foot></foot>
  </div>
</template>
<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
Vue.use(Tab);
Vue.use(Tabs);
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import "@css/resetui.css";
import $ from "jquery";
import $store from "../store";
import { scroll } from "@utils/scroll";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getNavsByCid, getResourceDisplay } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      pra: [],
      pract: {},
      practManualEdition: [],
      queryWhere: {},
      navs: [],
      backgroud: "",
      resourceDetail: {},
      tabActive: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },

  mounted: function () {
    let that = this;
    getNavsByCid({ id: 2 }).then(function (res) {
      that.$set(that, "navs", res.data);
      scroll($(".nice-scroll"));
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },

  methods: {
    getResourceDetail: function (queryId, index) {
      let that = this;
      that.queryWhere.id = queryId;
      that.tabActive = 0;
      getResourceDisplay({ id: queryId }).then(function (res) {
        that.$set(that, "resourceDetail", res.data);
      });
    },
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.id == "trigger0" ? el.click() : null;
      },
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;

  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.com_box {
  width: 1484px;
  height: 1660px;
  position: relative;
  margin: 0 auto;
}

.com-right::-webkit-scrollbar {
  display: none;
}
.com-right {
  overflow-y: auto;
}

.com-btt {
  width: 780px;
  height: 226px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1270px;
  left: 350px;
}
.com-btt > div {
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
}
.com-bbt-item {
  font-family: "楷体";
  font-size: 20px;
}
.com_right {
  overflow-y: auto;
}
.com-left-con {
  display: flex;
  justify-content: space-around;
  color: #fff;
  cursor: pointer;
}
.com-left-fot {
  height: 500px;
  overflow-y: auto;
}
.com-left-fot::-webkit-scrollbar {
  display: none;
}
.fot {
  text-align: center;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
.com-right-ul {
  width: 100%;
  height: 20px;
  display: flex;
  align-content: space-between;
}
.active2 {
  color: #900c0c;
}

.nav_list {
  position: relative;
  text-align: center;
  color: #fff;
  margin-top: 30px;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 3px;
}

.nav_list p.active {
  color: #d8c8a5;
}

.resource-display {
  width: 980px;
  height: 688px;
  position: relative;
  top: 415px;
  left: 146px;
  float: left;
  overflow-y: auto;
}

.resource-display::-webkit-scrollbar {
  display: none;
}

.resource-display >>> .content.addP p {
  margin: 0 auto;
  text-indent: 35px !important;
  margin-top: 30px;
  line-height: 2;
  padding: 0 30px;
  box-sizing: border-box;
}

.resource-display >>> .content table {
  position: relative;
  margin: 0px auto;
  border-radius: 5px !important;
  text-align: center !important;
  border-collapse: collapse;
  border-spacing: 0;
  width: 90%;
}
.resource-display >>> .content table tr {
  display: table-row;
}

.resource-display >>> .content table thead tr {
  background-color: #d2b889;
  color: #fff;
}

.resource-display >>> .content table th,
.resource-display >>> .content table td {
  position: relative;

  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff !important;
  width: auto !important;
  line-height: 34px;
}

.resource-display >>> .content table th {
  padding: 9px 15px;
}

.resource-display >>> .content table td a {
  position: relative;
  width: 100%;
  display: block;
  margin: 9px 15px;
}

.resource-display >>> .van-tabs__content {
  padding-top: 30px;
}

.resource-display >>> .content video {
  max-width: 85%;
}
</style>


